import { DTO } from '@bp/shared/models/metadata';
import {
	SubscriptionPlanPrice, SubscriptionPlanChargePeriod
} from '@bp/shared/domains/subscription-plans/core';

import { TRANSACTIONS_PLAN_LIMIT } from './transactions-plan-limit.fixture';

export class SubscriptionPlanPrices {

	static free = buildMockSubscriptionPlanPrice({
		monthPrice: 0,
		annualPrice: 0,
		transactionsLimit: TRANSACTIONS_PLAN_LIMIT.free,
	});

	static startup = buildMockSubscriptionPlanPrice({
		monthPrice: 239,
		annualPrice: 2388,
		transactionsLimit: TRANSACTIONS_PLAN_LIMIT.startup,
	});

	static pro = buildMockSubscriptionPlanPrice({
		monthPrice: 599,
		annualPrice: 5988,
		transactionsLimit: TRANSACTIONS_PLAN_LIMIT.pro,
	});

	static growth = buildMockSubscriptionPlanPrice({
		monthPrice: 1199,
		annualPrice: 11_988,
		transactionsLimit: TRANSACTIONS_PLAN_LIMIT.growth,
	 });

}

function buildMockSubscriptionPlanPrice(
	{
		monthPrice,
		annualPrice,
		transactionsLimit,
	}:
	{
		monthPrice: number;
		annualPrice: number;
		transactionsLimit: number;
	},
): Record<string, DTO<SubscriptionPlanPrice>> {
	const annualMonthPrice = annualPrice / 12;
	const annualDiscount = (monthPrice - annualMonthPrice) / monthPrice;
	const monthPricePerTransaction = monthPrice / transactionsLimit || 0.31;
	const annualPricePerTransaction = annualMonthPrice / transactionsLimit || 0.31;

	return {
		[SubscriptionPlanChargePeriod.monthly.name]: {
			discount: 0,
			isBasePrice: true,
			refundableForDays: 14,
			pricesPerCurrency: {
				usd: monthPrice,
				eur: monthPrice,
			},
			transactionsAmountTiers: [
				{
					to: transactionsLimit,
				},
				{
					to: null,
					pricePerTransaction: {
						usd: monthPricePerTransaction,
						eur: monthPricePerTransaction,
					},
				},
			],
		},
		[SubscriptionPlanChargePeriod.annually.name]: {
			discount: annualDiscount,
			refundableForDays: 30,
			pricesPerCurrency: {
				usd: annualPrice,
				eur: annualPrice,
			},
			transactionsAmountTiers: [
				{
					to: transactionsLimit,
				},
				{
					to: null,
					pricePerTransaction: {
						usd: annualPricePerTransaction,
						eur: annualPricePerTransaction,
					},
				},
			],
		},
	};
}
