import moment from 'moment';

import { IdentityJwtSessionDTO } from '@bp/shared/domains/jwt-session';
import { JwtToken } from '@bp/shared/utilities/core';

import { IIdentitySessionApiResponse } from '@bp/admins-shared/domains/identity/models';

export function buildMockApiIdentitySessionResponse(payload: IdentityJwtSessionDTO): IIdentitySessionApiResponse {
	return {
		accessToken: {
			token: JwtToken.encode<IdentityJwtSessionDTO>(payload),
			otpExpiresAt: payload.otpProvider === 'authApp' ? undefined : moment().add(62, 'seconds')
				.unix(),
		},
		refreshToken: '3b73492a-18aa-4238-be1e-df49ec74014f',
	};
}
