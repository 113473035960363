import { isNil, range } from 'lodash-es';
import { Response } from 'miragejs';
import moment from 'moment';

import {
	getIdentityJwtSession, getMockUsersCredentials, MockUserEmail, IdentityJwtSessionDTO,
	getNeverExpiringEpoch, getMerchantAdminMockUsersCredentials, getBridgerAdminMockUsersCredentials
} from '@bp/shared/domains/jwt-session';
import { DTO } from '@bp/shared/models/metadata';
import { MerchantAdminFeature } from '@bp/shared/domains/permissions';
import { Platform } from '@bp/shared/typings';

import {
	ApiMockPlugin, MOCK_EMPTY_OK_API_RESPONSE, MOCK_FORBIDDEN_API_RESPONSE, buildMockBadRequestApiResponse
} from '@bp/frontend/api-mocking';

import {
	GenerateOtpApiResponse, ILoginApiRequest, SecurityQuestion
} from '@bp/admins-shared/domains/identity/models';

import { buildMockApiIdentitySessionResponse } from '../utils';
import { AUTH_PATH_SEGMENT } from '../constants/api-paths';

export default class IdentityApiMockPlugin extends ApiMockPlugin {

	constructor(platform?: Platform) {
		super({
			routes() {
				const namespace = `/${ AUTH_PATH_SEGMENT }`;

				this.post(`${ namespace }/login`, (_scheme, { requestBody }) => {
					const { userName, oauthIdToken } = <ILoginApiRequest>JSON.parse(requestBody);
					let mockUserName = <MockUserEmail>userName;

					if (userName === '303@test.com') {
						return new Response(303, {
							// eslint-disable-next-line @typescript-eslint/naming-convention
							Location: 'https://www.google.com/',
						});
					}

					if (oauthIdToken)
						mockUserName = MockUserEmail.Pro;

					if (isNil(getMockUsersCredentials()[mockUserName]))
						return MOCK_FORBIDDEN_API_RESPONSE;

					const mockUser = <IdentityJwtSessionDTO>(function() {
						switch (platform) {
							case 'bridger-admin':
								// @ts-expect-error Only ba roles will be there
								return getBridgerAdminMockUsersCredentials()[mockUserName];

							case 'merchant-admin':
								// @ts-expect-error Only ma roles will be there
								return getMerchantAdminMockUsersCredentials()[mockUserName];

							default:
								return getMockUsersCredentials()[mockUserName];
						}
					}());

					return buildMockApiIdentitySessionResponse({
						...mockUser,
						permissions: [ MockUserEmail.Free, MockUserEmail.ProEmailOtp ].includes(mockUserName)
							? [ MerchantAdminFeature.otpVerify.claim ]
							: mockUser.permissions,
					});
				});

				this.post(`${ namespace }/refresh-token`, (_scheme, { requestHeaders }) => {
					const identityJwtSession = getIdentityJwtSession(requestHeaders);

					identityJwtSession.exp = getNeverExpiringEpoch();

					if (identityJwtSession.userEmail === MockUserEmail.Pro)
						identityJwtSession.permissions = identityJwtSession.permissions!.map(permission => permission.replace('paywall', 'otp_required__short_ttl'));

					return buildMockApiIdentitySessionResponse(identityJwtSession);
				});

				this.post(`${ namespace }/verify-email`, (_scheme, { requestHeaders }) => buildMockApiIdentitySessionResponse({
					...getMockUserBasedOnIdentityJwtUserEmail(requestHeaders),
					permissions: [ MerchantAdminFeature.createAccount.claim ],
				}));

				this.post(
					`${ namespace }/create-password`,
					(_scheme, { requestHeaders }) => buildMockApiIdentitySessionResponse({
						...getMockUserBasedOnIdentityJwtUserEmail(requestHeaders),
						permissions: [ MerchantAdminFeature.setSecurityQuestionsAnswers.claim ],
					}),
				);

				this.post(`${ namespace }/reset-password`, (_scheme, { requestHeaders }) => buildMockApiIdentitySessionResponse(getMockUserBasedOnIdentityJwtUserEmail(requestHeaders)));

				this.post(`${ namespace }/change-password`, (_scheme, { requestHeaders }) => buildMockApiIdentitySessionResponse(getMockUserBasedOnIdentityJwtUserEmail(requestHeaders)));

				this.post(`${ namespace }/otp/reset-authenticator`, (_scheme, { requestHeaders }) => buildMockApiIdentitySessionResponse(getMockUserBasedOnIdentityJwtUserEmail(requestHeaders)));

				this.post(
					`${ namespace }/otp/reset-password/verify/:code`,
					(_scheme, { requestHeaders, params: { code } }) => {
						if (Number(code) === 666_666) {
							return buildMockBadRequestApiResponse({
								message: 'Code has expired, try another one',
								field: 'code',
							});
						}

						if (Number(code) === 777_777) {
							return buildMockBadRequestApiResponse({
								message: 'Code has expired, try another one',
							});
						}

						return buildMockApiIdentitySessionResponse({
							...getMockUserBasedOnIdentityJwtUserEmail(requestHeaders),
							permissions: [ MerchantAdminFeature.resetPassword.claim ],
						});
					},
				);

				this.post(`${ namespace }/otp/verify/:code`, (_scheme, { requestHeaders, params: { code } }) => {
					if (Number(code) === 666_666) {
						return buildMockBadRequestApiResponse({
							message: 'Code has expired, try another one',
							field: 'code',
						});
					}

					if (Number(code) === 777_777) {
						return buildMockBadRequestApiResponse({
							message: 'Code has expired, try another one',
						});
					}

					return buildMockApiIdentitySessionResponse(getMockUserBasedOnIdentityJwtUserEmail(requestHeaders));
				});

				this.get(`${ namespace }/all-security-questions`, (_scheme): DTO<SecurityQuestion>[] => [ 'In what city were you born?', 'What is the name of your favorite pet?', 'What is your mother`s maiden name?', 'What is the name of the first person that you fall in love?', 'What was your favorite food as a child?' ]
					.map((text, id) => ({ id, text })));

				this.post(`${ namespace }/set-security-questions-answers`, (_scheme, { requestBody, requestHeaders }) => {
					const answers = <Record<string, string>[]>JSON.parse(requestBody);
					const mockAnswer = answers[0]['answer'];

					if (Number(mockAnswer) === 666_666) {
						return buildMockBadRequestApiResponse({
							message: 'Wrong answer',
						});
					}

					if (Number(mockAnswer) === 666) {
						return buildMockBadRequestApiResponse([
							{
								type: 'mock_bad_request_api_response',
								field: 'answers[0].answer',
								message: 'Wrong answer',
							},
							{
								type: 'mock_bad_request_api_response',
								field: 'answers[1].answer',
								message: 'Wrong answer',
							},
						]);
					}

					return buildMockApiIdentitySessionResponse({
						...getMockUserBasedOnIdentityJwtUserEmail(requestHeaders),
						permissions: [ MerchantAdminFeature.registerAuthenticator.claim ],
						exp: getNeverExpiringEpoch(),
					});
				});

				this.get(`${ namespace }/security-questions`, (_scheme): DTO<SecurityQuestion>[] => range(3).map(index => ({
					id: index,
					text: `Very Secure Question ${ index + 1 }`,
				})));

				this.post(`${ namespace }/verify-security-questions-answers`, (_scheme, { requestHeaders }) => {
					const identityJwtSession = getIdentityJwtSession(requestHeaders);

					identityJwtSession.permissions = identityJwtSession.permissions!.includes(
						MerchantAdminFeature.verifyAnswerBeforeResetAuthenticator.claim,
					)
						? [ MerchantAdminFeature.resetAuthenticator.claim ]
						: [ MerchantAdminFeature.resetPassword.claim ];

					return buildMockApiIdentitySessionResponse(identityJwtSession);
				});

				this.post(`${ namespace }/reset-password-link`, _scheme => MOCK_EMPTY_OK_API_RESPONSE);

				this.post(`${ namespace }/otp/reset-authenticator-link`, _scheme => MOCK_EMPTY_OK_API_RESPONSE);

				this.get(
					`${ namespace }/otp/qr-code-key`,
					_scheme => new Response(200, {}, 'FUXBXLYIHBUIAIOH7NSRQE5TYV24OCRX'),
				);

				this.post(`${ namespace }/otp/register-authenticator`, (_scheme, { requestBody, requestHeaders }) => {
					const { code } = <Record<string, string>>JSON.parse(requestBody);

					if (Number(code) === 666_666) {
						return buildMockBadRequestApiResponse({
							message: 'Code has expired, try another one',
							field: 'code',
						});
					}

					if (Number(code) === 777_777) {
						return buildMockBadRequestApiResponse({
							message: 'Code has expired, try another one',
						});
					}

					return buildMockApiIdentitySessionResponse(getMockUserBasedOnIdentityJwtUserEmail(requestHeaders));
				});

				this.post(
					`${ namespace }/otp/:claim/generate`,
					(_scheme): DTO<GenerateOtpApiResponse> => ({
						otpExpiresAt: moment().add(75, 'seconds')
							.unix(),
					}),
				);

				this.post(
					`${ namespace }/otp/:claim/verify/:code`,
					(_scheme, { params: { claim, code } }) => {
						if (Number(code) === 666_666) {
							return buildMockBadRequestApiResponse({
								message: 'Code has expired, try another one',
								field: 'code',
							});
						}

						if (Number(code) === 777_777) {
							return buildMockBadRequestApiResponse({
								message: 'Code has expired, try another one',
							});
						}

						if (claim.includes('single_use'))
							return MOCK_EMPTY_OK_API_RESPONSE;

						return {
							claim,
							expiresAt: moment()
								.add(
									claim.includes('short_ttl') ? 30 : 60,
									'seconds',
								),
						};
					},
				);

				return namespace;
			},
		});
	}
}

export function getMockUserBasedOnIdentityJwtUserEmail(requestHeaders: Record<string, string>): IdentityJwtSessionDTO {
	return getMockUsersCredentials()[<MockUserEmail>getIdentityJwtSession(requestHeaders).userEmail];
}
