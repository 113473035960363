import { CheckoutUrlsDTO } from '@bp/shared/domains/checkouts';
import { FiatCurrency } from '@bp/shared/models/currencies';
import { toPlainObject } from '@bp/shared/utilities/core';

import { CheckoutTheme, CheckoutType } from '@bp/frontend/domains/checkout/core';
import { Checkout, CheckoutPaymentMethod, CheckoutStatus } from '@bp/frontend/domains/checkout/models';
import { PaymentOptionType } from '@bp/frontend/models/business';

const urls = {
	failure: 'http://mock.mock',
	notification: 'http://mock.mock',
	success: 'http://mock.mock',
	cancel: 'http://mock.mock',
} satisfies CheckoutUrlsDTO;

export default toPlainObject<Checkout[]>([
	new Checkout({
		id: '0fc714a5-efc2-4863-8c21-9bb6f87e663c',
		title: 'VirtualTerminal',
		button: null,
		languages: [ 'en-US' ],
		type: CheckoutType.virtualTerminal,
		theme: CheckoutTheme.dark,
		status: CheckoutStatus.active,
		baseCurrency: new FiatCurrency('USD'),
		paymentMethods: [
			new CheckoutPaymentMethod({
				type: PaymentOptionType.creditCard,
				provider: 'none',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 5000,
				amount: 100,
				brands: [],
				currencies: FiatCurrency.fromCodes([
					'USD',
					'EUR',
					'GBP',
					'RUB',
					'MYR',
					'AUD',
					'NZD',
					'VND',
					'THB',
					'ISK',
					'INR',
					'JOD',
					'JPY',
					'ZAR',
				]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.apm,
				provider: 'zip',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 500_000,
				amount: 100,
				currencies: [ new FiatCurrency('AUD') ],
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.wireTransfer,
				provider: 'bank_wire_static',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 500,
				amount: 500,
				currencies: [ new FiatCurrency('USD') ],
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.cryptoWallet,
				provider: 'crypto_static',
				name: null,
				is3dSecure: true,
				minAmount: 100,
				maxAmount: 500,
				amount: 500,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.externalLink,
				provider: 'bitisland',
				name: null,
				is3dSecure: true,
				minAmount: 100,
				maxAmount: 500,
				amount: 500,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
		],
	}),
	new Checkout({
		id: 'd3eeeedb-935e-44c0-8bf6-5233e336f91b',
		url: {
			failure: '',
			notification: '',
			success: '',
			cancel: '',
		},
		title: 'Regular',
		licenseKey: '81922af3-8896-41c5-ac1a-3485bc755fd4',
		button: null,
		languages: [ 'en-US' ],
		type: CheckoutType.regular,
		theme: CheckoutTheme.light,
		status: CheckoutStatus.active,
		baseCurrency: new FiatCurrency('USD'),
		pluginName: 'WebSdkEmbed',
		paymentMethods: [
			new CheckoutPaymentMethod({
				type: PaymentOptionType.creditCard,
				provider: 'none',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 5000,
				amount: 100,
				brands: [],
				currencies: FiatCurrency.fromCodes([
					'USD',
					'EUR',
					'GBP',
					'RUB',
					'MYR',
					'AUD',
					'NZD',
					'VND',
					'THB',
					'ISK',
					'INR',
					'JOD',
					'JPY',
					'ZAR',
				]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.apm,
				provider: 'zip',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 500_000,
				amount: 100,
				currencies: [ new FiatCurrency('AUD') ],
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.wireTransfer,
				provider: 'bank_wire_static',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 500,
				amount: 500,
				currencies: [ new FiatCurrency('USD') ],
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.crypto,
				provider: 'afterpay',
				name: null,
				minAmount: 10,
				maxAmount: 5000,
				amount: 10,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.cryptoWallet,
				provider: 'crypto_static',
				name: null,
				is3dSecure: true,
				minAmount: 100,
				maxAmount: 500,
				amount: 500,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.externalLink,
				provider: 'bitisland',
				name: null,
				is3dSecure: true,
				minAmount: 100,
				maxAmount: 500,
				amount: 500,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
		],
	}),
	new Checkout({
		id: 'bbc86ee5-75ce-4cf8-bf6e-71981a4d4cfb',
		url: urls,
		title: 'Woocommerce',
		licenseKey: '8d70c28c-5aa2-4fac-97ec-2c577f19532a',
		pluginName: 'Woocommerce',
		button: null,
		languages: [ 'en-US' ],
		type: CheckoutType.regular,
		theme: CheckoutTheme.light,
		status: CheckoutStatus.active,
		baseCurrency: new FiatCurrency('USD'),
		paymentMethods: [
			new CheckoutPaymentMethod({
				type: PaymentOptionType.creditCard,
				provider: 'none',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 5000,
				amount: 100,
				brands: [],
				currencies: FiatCurrency.fromCodes([
					'USD',
					'EUR',
					'GBP',
					'RUB',
					'MYR',
					'AUD',
					'NZD',
					'VND',
					'THB',
					'ISK',
					'INR',
					'JOD',
					'JPY',
					'ZAR',
				]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.apm,
				provider: 'zip',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 500_000,
				amount: 100,
				currencies: [ new FiatCurrency('AUD') ],
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.wireTransfer,
				provider: 'bank_wire_static',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 500,
				amount: 500,
				currencies: [ new FiatCurrency('USD') ],
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.crypto,
				provider: 'afterpay',
				name: null,
				is3dSecure: true,
				minAmount: 10,
				maxAmount: 5000,
				amount: 10,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.cryptoWallet,
				provider: 'crypto_static',
				name: null,
				is3dSecure: true,
				minAmount: 100,
				maxAmount: 500,
				amount: 500,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.externalLink,
				provider: 'bitisland',
				name: null,
				is3dSecure: true,
				minAmount: 100,
				maxAmount: 500,
				amount: 500,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
		],
	}),
	new Checkout({
		id: 'efa1d74b-56b1-4a4c-9751-6f8bd3f30c6d',
		url: urls,
		title: 'Wordpress',
		licenseKey: '24628c2e-33aa-469d-a3d6-1e2a5ffb0d13',
		button: null,
		languages: [ 'en-US' ],
		type: CheckoutType.regular,
		theme: CheckoutTheme.light,
		status: CheckoutStatus.notActive,
		baseCurrency: new FiatCurrency('USD'),
		paymentMethods: [
			new CheckoutPaymentMethod({
				type: PaymentOptionType.creditCard,
				provider: 'none',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 5000,
				amount: 100,
				brands: [],
				currencies: FiatCurrency.fromCodes([
					'USD',
					'EUR',
					'GBP',
					'RUB',
					'MYR',
					'AUD',
					'NZD',
					'VND',
					'THB',
					'ISK',
					'INR',
					'JOD',
					'JPY',
					'ZAR',
				]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.apm,
				provider: 'zip',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 500_000,
				amount: 100,
				currencies: [ new FiatCurrency('AUD') ],
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.wireTransfer,
				provider: 'bank_wire_static',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 500,
				amount: 500,
				currencies: [ new FiatCurrency('USD') ],
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.crypto,
				provider: 'afterpay',
				name: null,
				is3dSecure: true,
				minAmount: 10,
				maxAmount: 5000,
				amount: 10,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.cryptoWallet,
				provider: 'crypto_static',
				name: null,
				is3dSecure: true,
				minAmount: 100,
				maxAmount: 500,
				amount: 500,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
			new CheckoutPaymentMethod({
				type: PaymentOptionType.externalLink,
				provider: 'bitisland',
				name: null,
				is3dSecure: true,
				minAmount: 100,
				maxAmount: 500,
				amount: 500,
				currencies: FiatCurrency.fromCodes([ 'GBP', 'USD', 'EUR' ]),
			}),
		],
	}),
	new Checkout({
		id: '84eda43d-e10d-468f-85fb-8fef7e9750a3',
		url: urls,
		title: 'Magento',
		licenseKey: '44d5d6d0-8a8b-4151-a869-b948f393b24f',
		button: null,
		languages: [ 'en-US' ],
		type: CheckoutType.regular,
		theme: CheckoutTheme.light,
		status: CheckoutStatus.notActive,
		baseCurrency: new FiatCurrency('USD'),
		paymentMethods: [
			new CheckoutPaymentMethod({
				type: PaymentOptionType.creditCard,
				provider: 'none',
				name: null,
				is3dSecure: true,
				minAmount: 1,
				maxAmount: 5000,
				amount: 100,
				brands: [],
				currencies: FiatCurrency.fromCodes([
					'USD',
					'EUR',
					'GBP',
					'RUB',
					'MYR',
					'AUD',
					'NZD',
					'VND',
					'THB',
					'ISK',
					'INR',
					'JOD',
					'JPY',
					'ZAR',
				]),
			}),
		],
	}),
]);
