export const INTEGRATED_PSP_NAMES_FIXTURE = [
	{ name: 'GooglePay' },
	{ name: 'Nuvei' },
	{ name: 'ZotaPay' },
	{ name: 'Certus' },
	{ name: 'Coriunder' },
	{ name: 'PayFinder' },
	{ name: 'VoguePay' },
	{ name: 'Tranzzo' },
	{ name: 'SafeCharge' },
	{ name: 'Ecommpay' },
	{ name: 'WireCard' },
	{ name: 'Astech' },
	{ name: 'CardPay' },
	{ name: 'Transact' },
	{ name: 'B2BinPay' },
	{ name: 'Jubiter' },
	{ name: 'Netpay' },
	{ name: 'Ikajo' },
	{ name: 'Coinipop' },
	{ name: 'BitcoBrokers' },
	{ name: 'Truevo' },
	{ name: 'Fibonatix' },
	{ name: 'WireCapital' },
	{ name: 'MasterPay' },
	{ name: 'Help2Pay' },
	{ name: 'PayTrust' },
	{ name: 'BPay' },
	{ name: 'Regularpay' },
	{ name: 'PaymentCenter' },
	{ name: 'Payretailers' },
	{ name: 'IPayTotal' },
	{ name: 'Flexipin' },
	{ name: 'ISignthis' },
	{ name: 'Skrill' },
	{ name: 'Flutterwave' },
	{ name: 'Innatech' },
	{ name: 'Paysafe' },
	{ name: 'PaymentZ' },
	{ name: 'Foxapay' },
	{ name: 'IPayTotalV2' },
	{ name: 'GlobePay' },
	{ name: 'Tranzilla' },
	{ name: 'AstroPay' },
	{ name: 'Gigadat' },
	{ name: 'Worldpay' },
	{ name: 'VLoad' },
	{ name: 'SettleMe' },
	{ name: 'Sofort' },
	{ name: 'BipiPay' },
	{ name: 'QubePay' },
	{ name: 'SolidPay' },
	{ name: 'OrangePay' },
	{ name: 'Directa24' },
	{ name: 'KunaExchange' },
	{ name: 'ApcoPay' },
	{ name: 'Payboutique' },
	{ name: 'CryptoAcquirer' },
	{ name: 'Pay2bee' },
	{ name: 'CoinVaders' },
	{ name: 'Intercassa' },
	{ name: 'Xpate' },
	{ name: 'Introrix' },
	{ name: 'HyperPay' },
	{ name: 'Goldpsp' },
	{ name: 'TotalProcessing' },
	{ name: 'NganLuong' },
	{ name: 'FasaPay' },
	{ name: 'Cypix' },
	{ name: 'Cellaris' },
	{ name: 'Epg' },
	{ name: 'Orpheus' },
	{ name: 'AcquiringCom' },
	{ name: 'VirtualPay' },
	{ name: 'PayPal' },
	{ name: 'PerfectMoney' },
	{ name: 'Odeon' },
	{ name: 'SepaExpress' },
	{ name: 'Jcc' },
	{ name: 'Pradexx' },
	{ name: 'PaymentAsia' },
	{ name: 'OneWorldPayment' },
	{ name: 'EasyEft' },
	{ name: 'FsTechnologies' },
	{ name: 'Quamtor' },
	{ name: 'ThunderxPay' },
	{ name: 'PaymentBillsService' },
	{ name: 'Fondy' },
	{ name: 'Decta' },
	{ name: 'Trustly' },
	{ name: 'EcoPayz' },
	{ name: 'RpnPay' },
	{ name: 'XPay' },
	{ name: 'OneRoadPayments' },
	{ name: 'PayinPay' },
	{ name: 'SaltarPay' },
	{ name: 'Cryptofactory' },
	{ name: 'Solid' },
	{ name: 'Quickcard' },
	{ name: 'RealDeposit' },
	{ name: 'Vadlopay' },
	{ name: 'PayInPay' },
	{ name: 'Pbs' },
	{ name: 'BankOne' },
	{ name: 'NeoBanq' },
	{ name: 'Payvision' },
	{ name: 'Tbn' },
	{ name: 'Sticpay' },
	{ name: 'Tinkbit' },
	{ name: 'Apg' },
	{ name: 'Axium' },
	{ name: 'Pagopay' },
	{ name: 'Atomixpay' },
	{ name: 'PlusExchange' },
	{ name: 'GatewayHub' },
	{ name: 'Flexipay' },
	{ name: 'CheckoutCom' },
	{ name: 'Fido' },
	{ name: 'Dinarak' },
	{ name: 'Gate2pay' },
	{ name: 'Stripe' },
	{ name: 'Worldcard' },
	{ name: 'Eupayments' },
	{ name: 'Toriox' },
	{ name: 'Safaricom' },
	{ name: 'Gmo' },
	{ name: 'Piastrix' },
	{ name: 'Paythrone' },
	{ name: 'Emerchantpay' },
	{ name: 'Zip' },
	{ name: 'Afterpay' },
	{ name: 'PeachPayments' },
	{ name: 'Paystra' },
	{ name: 'Humm' },
	{ name: 'Zimpler' },
	{ name: 'Quickbit' },
	{ name: 'Callpay' },
	{ name: 'Gatetopay' },
	{ name: 'Zapper' },
	{ name: 'Eximbpay' },
	{ name: 'Bitisland' },
	{ name: 'Clear' },
	{ name: 'Scuderia' },
	{ name: 'Limepay' },
	{ name: 'Hoolah' },
	{ name: 'Otp' },
	{ name: 'Powercash21' },
	{ name: 'Acquiringcom' },
	{ name: 'GlobalPayments' },
	{ name: 'Klarna' },
	{ name: 'Latitudepay' },
	{ name: 'Poli' },
	{ name: 'SafeCharge2' },
	{ name: 'Ezeebill' },
	{ name: 'Hayvn' },
	{ name: 'Paymero' },
	{ name: 'Jeton' },
	{ name: 'Pace' },
	{ name: 'ZotapayApm' },
	{ name: 'IQash' },
	{ name: 'Meikopay' },
	{ name: 'Beeteller' },
	{ name: 'Transact365' },
	{ name: 'Reach' },
	{ name: 'Braintree' },
	{ name: 'IngenicoEPayments' },
	{ name: 'IDeal' },
	{ name: 'Rupeepay' },
	{ name: 'PayU' },
	{ name: 'Razorpay' },
	{ name: 'Boleto' },
	{ name: 'Splitit' },
	{ name: 'AliPay' },
	{ name: 'Pagopay' },
	{ name: 'UnionPayInternational' },
	{ name: 'BlueSnap' },
];
