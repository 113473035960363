import { combineLatest, map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { CurrentOrganizationFacade } from '@bp/merchant-admin/frontend/domains/current-organization';
import { MerchantPspsFacade } from '@bp/merchant-admin/frontend/domains/merchant-psps';

@Injectable({ providedIn: 'root' })
export class PspsService {

	readonly bridgerPspInternalNamesWithMerchantPspsFirst$: Observable<string[]> = combineLatest([
		inject(CurrentOrganizationFacade).bridgerPspsInternalNames$,
		inject(MerchantPspsFacade).allPresent$.pipe(
			map(psps => new Set(psps.map(psp => psp.bridgerPsp?.internalName))),
		),
	]).pipe(
		map(([ bridgerPspsInternalNames, merchantPspsInternalNames ]) => bridgerPspsInternalNames.sort(
			(a, b) => Number(merchantPspsInternalNames.has(b)) - Number(merchantPspsInternalNames.has(a)),
		)),
	);

}
