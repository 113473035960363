import {
	Control, FieldControlType, Hint, MetadataEntity, Property, Required
} from '@bp/shared/models/metadata';
import {
	cancelRedirectUrlPropertyMetadata, failurePropertyMetadata, successRedirectUrlPropertyMetadata,
	webhookUrlPropertyMetadata
} from '@bp/shared/domains/checkouts';

export class CheckoutActivationRequest extends MetadataEntity {

	@Property(webhookUrlPropertyMetadata)
	readonly webhookUrl!: string;

	@Property(successRedirectUrlPropertyMetadata)
	readonly successRedirectUrl!: string;

	@Property(cancelRedirectUrlPropertyMetadata)
	readonly cancelRedirectUrl!: string;

	@Property(failurePropertyMetadata)
	readonly failure!: string;

	@Required()
	@Control(FieldControlType.url)
	@Hint('URL of your website')
	readonly domain!: string;

	readonly pluginType = 'websdk-embed';

}
