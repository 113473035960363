import { Enumeration } from '@bp/shared/models/core/enum';

export class MerchantPspPageTab extends Enumeration {

	static details = new MerchantPspPageTab();

	static credentials = new MerchantPspPageTab();

	static threeDsCredentials = new MerchantPspPageTab();

	static integrationGuides = new MerchantPspPageTab();

}
