import { DTO } from '@bp/shared/models/metadata';
import {
	SubscriptionPlan, SubscriptionPlanChargePeriod, SubscriptionPlanType
} from '@bp/shared/domains/subscription-plans/core';

import { SubscriptionPlanPrices } from './prices.fixtures';
import { TRANSACTIONS_PLAN_LIMIT } from './transactions-plan-limit.fixture';

export const DEFAULT_SUBSCRIPTION_PLANS_GROUP_ID = 'e5befaa9-7395-4af5-81a7-170f2e19079e';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
export const DEFAULT_SUBSCRIPTION_PLANS = <DTO<SubscriptionPlan>[]>[
	// {
	// 	id: '11b4da33-6f5f-49c5-a537-0503795513ec',
	// 	plansGroupId: DEFAULT_SUBSCRIPTION_PLANS_GROUP_ID,
	// 	name: 'Free Forever',
	// 	type: SubscriptionPlanType.free,
	// 	outline: 'For individuals looking to operate their payments',
	// 	prices: SubscriptionPlanPrices.free,
	// 	limits: {
	// 		transactions: TRANSACTIONS_PLAN_LIMIT.free,
	// 		users: 1,
	// 		checkouts: 1,
	// 		merchantPsps: 2,
	// 	},
	// 	features: [
	// 		{
	// 			name: 'Plugin Integration',
	// 		},
	// 		{
	// 			name: 'Bridger Admin',
	// 		},
	// 		{
	// 			name: 'Bridger Retry™',
	// 			description: 'Our proprietary technology to rescue declined transactions and save them by automatically cascading to another PSP',
	// 		},
	// 		{
	// 			name: 'PSP expert call',
	// 		},
	// 	],
	// },
	{
		id: '2b60692b-1181-4e8c-81a3-a5464fc3b684',
		plansGroupId: DEFAULT_SUBSCRIPTION_PLANS_GROUP_ID,
		name: 'Start-Up',
		type: SubscriptionPlanType.startup,
		outline: 'An end-to-end payment stack that grows with your start-up',
		prices: SubscriptionPlanPrices.startup,
		limits: {
			transactions: TRANSACTIONS_PLAN_LIMIT.startup,
			users: 5,
			checkouts: 3,
			merchantPsps: 4,
		},
		features: [
			{
				name: 'Refunds',
				description: 'Issue refunds directly from your Bridger Admin',
			},
			{
				name: 'Bridger Reports',
				description: 'Get never-seen-before insights. With all your payments data in one place, improving your strategies is a breeze!',
			},
			{
				name: 'Bridger Safe',
				description: 'Tokenize all customers\' data in BridgerPay, with no need for sensitive information to pass through your servers',
			},
			{
				name: 'Onboarding Specialist',
			},
			{
				name: 'Multilingual Checkout',
			},
		],
	},
	{
		id: '96e1746f-fbdb-4d39-aa98-5c4fc61f20b8',
		plansGroupId: DEFAULT_SUBSCRIPTION_PLANS_GROUP_ID,
		name: 'Pro',
		type: SubscriptionPlanType.pro,
		outline: 'For businesses at scale that want to optimize their payment flows',
		isMostPopular: true,
		prices: SubscriptionPlanPrices.pro,
		limits: {
			transactions: TRANSACTIONS_PLAN_LIMIT.pro,
			users: 5,
			checkouts: 8,
			merchantPsps: 10,
		},
		features: [
			{
				name: 'Free new PSP integrations',
				count: 1,
			},
			{
				name: 'Rules Engine',
				description: 'Further tune your payment stack by setting custom rules based on location, card type, currency, value and dozens of other criteria',
			},
			{
				name: 'Google/Apple Pay Support',
			},
		],
	},
	{
		id: 'dd860b5e-748f-44d3-a735-4e23f14dc73d',
		plansGroupId: DEFAULT_SUBSCRIPTION_PLANS_GROUP_ID,
		name: 'Growth',
		type: SubscriptionPlanType.growth,
		outline: 'Allow your company to grow faster and reach any market worldwide',
		prices: SubscriptionPlanPrices.growth,
		limits: {
			transactions: TRANSACTIONS_PLAN_LIMIT.growth,
			users: 10,
			checkouts: 15,
			merchantPsps: 15,
		},
		features: [
			{
				name: 'Free new PSP integrations',
				count: 2,
			},
			{
				name: 'Integration Engineer',
				description: 'A member of our team will onboard you and help you setup and customize BridgerPay to your specific needs.',
			},
			{
				name: 'Marketplace Support',
			},
			{
				name: 'Customized Checkout',
				description: 'Customize our PCI level 1 compliant checkout to match your website\'s look and feel',
			},
			{
				name: 'Dynamic Currency Converter',
			},
			{
				name: 'Account manager',
				description: 'Connect with our amazing support team to get all the help you need :)',
			},
		],
	},
	{
		id: '56ec9e8d-aaf7-42b3-976c-dda1b8489763',
		plansGroupId: DEFAULT_SUBSCRIPTION_PLANS_GROUP_ID,
		name: 'Enterprise',
		type: SubscriptionPlanType.enterprise,
		outline: 'Connect, manage, and optimize unlimited connections and payments',
		limits: {
			users: null,
			transactions: null,
			merchantPsps: null,
			checkouts: null,
		},
		prices: {
			[SubscriptionPlanChargePeriod.monthly.name]: {
				discount: 1,
				pricesPerCurrency: {
					usd: 5000,
					eur: 5000,
				},
				transactionsAmountTiers: [
					{
						to: null,
						pricePerTransaction: {
							usd: 0.99,
							eur: 0.99,
						},
					},
				],
			},
			[SubscriptionPlanChargePeriod.annually.name]: {
				discount: 1,
				pricesPerCurrency: {
					usd: 5000 * 12,
					eur: 5000 * 12,
				},
				transactionsAmountTiers: [
					{
						to: null,
						pricePerTransaction: {
							usd: 0.99,
							eur: 0.99,
						},
					},
				],
			},
		},
		features: [
			{
				name: 'Free new PSP integrations',
				count: 3,
			},
			{
				name: 'Bridger BI',
				description: 'Gain tailored insights about how your PSPs are performing and make more targeted decisions',
			},
			{
				name: 'Automations',
				description: 'Coming soon. Integrate and automate different platforms directly into BridgerPay (for example Slack and GMail notifications)',
			},
			{
				name: 'Payouts',
			},
			{
				name: 'Success & Support Manager',
			},
		],
	},
];

export const DEFAULT_SUBSCRIPTION_PLANS_PER_TYPE = new Map(
	DEFAULT_SUBSCRIPTION_PLANS.map(plan => [ plan.type, plan ]),
);
