
import { FiatCurrency } from '@bp/shared/models/currencies';
import { Control, Default, Entity, FieldControlType, MapFromDTO, Mapper, Required } from '@bp/shared/models/metadata';

import { PaymentOptionType } from '@bp/frontend/models/business';

export class CheckoutLimitsSettingsEdit extends Entity {
	@Mapper(PaymentOptionType)
	paymentMethodType!: PaymentOptionType;

	@Default('none')
	provider!: string;

	@Required()
	@Control(FieldControlType.number)
	minAmount!: number;

	@Required()
	@Control(FieldControlType.number)
	maxAmount!: number;

	@Required()
	@Control(FieldControlType.number)
	defaultAmount!: number;

	@Mapper(FiatCurrency)
	baseCurrency!: FiatCurrency;

	@MapFromDTO()
	title!: string;
}
